/* eslint-disable max-len */
import { ageCategoriesKeyLabelTooltip } from 'graphql/participants/models';

const en = {
  entities: {
    study: {
      study_code: 'Study Code',
      study: 'Study',
      studies: 'Studies',
      studyAuto: '{count, plural, =0 {Study} =1 {Study} other {Studies}}',
      access_limitations: 'Access Limitations',
      access_limitation: 'Access Limitation',
      access_requirements: 'Access Requirements',
      access_requirement: 'Access Requirement',
      access_authority: 'Access Authority',
      domain: 'Domain',
      population: 'Population',
      data_access_codes: {
        access_limitations: 'Access Limitation',
        access_requirements: 'Access Requirement',
      },
      statistic: 'Summary Statistic',
      statistics: 'Summary Statistics',
      data_access: 'Data Access',
      name: 'Name',
      keywords: 'Keywords',
      description: 'Description',
      restrictedTitle: 'Restricted Study',
      restrictedContent: 'The data of this study is not accessible for exploration.',
      restrictedContact:
        'The access request documents cannot be downloaded for this study. To proceed with your request for access please contact directly the Access Authority.',
      restrictedContact2:
        'You can find the contact information for the Access Authority in the Data Access table in the Studies page.',
      CQDGStudies: 'CQDG Studies',
    },
    biospecimen: {
      sample_id: 'Sample ID',
      submitter_sample_id: 'External Sample ID',
      sample: 'Sample',
      samples: 'Samples',
      samplesAuto: '{count, plural, =0 {Sample} =1 {Sample} other {Samples}}',
      sample_type: 'Sample Type',
      biospecimensAuto: '{count, plural, =0 {Biospecimen} =1 {Biospecimen} other {Biospecimens}}',
      biospecimens: 'Biospecimens',
      biospecimen: 'Biospecimen',
      biospecimen_tissue_source: 'Tissue',
      biospecimen_id: 'Biospecimen ID',
      age: 'Age',
      age_biospecimen_collection: 'Age at Biospecimen Collection',
    },
    participant: {
      participant_id: 'Participant ID',
      participant_2_id: 'Participant ID',
      submitter_participant_id: 'External Participant ID',
      participant: 'Participant',
      participants: 'Participants',
      id: 'ID',
      participantAuto: '{count, plural, =0 {Participant} =1 {Participant} other {Participants}}',
      participantsSamples: 'Participants / Samples',
      participantSample: 'Participant / Sample',
      profile: 'Profile',
      family: 'Family',
      families: 'Families',
      diagnosis: 'Diagnosis',
      diagnoses: 'Diagnoses',
      diagnosis_mondo: 'Diagnosis (MONDO)',
      diagnosis_icd10: 'Diagnosis (ICD-10)',
      diagnosis_icd: 'Diagnosis (ICD)',
      diagnosis_source_text: 'Diagnosis (Source Text)',
      age_at_diagnosis: 'Age at Diagnosis',
      age_at_phenotype: 'Age at Phenotype',
      phenotype: 'Phenotype',
      phenotypes: 'Phenotypes',
      phenotype_hpo: 'Phenotype (HPO)',
      family_type: 'Family Type',
      family_position: 'Family Position',
      family_id: 'Family ID',
      sex: 'Sex',
      demographic: 'Demographic',
      population: 'Population',
      ethnicity: 'Ethnicity',
      age: 'Age',
      age_at_recruitment: 'Age at Recruitment',
      vital_status: 'Vital Status',
      age_of_death: 'Age at Death',
      cause_of_death: 'Cause of Death',
      disease_status: 'Disease Status',
      cancer: 'Cancer',
      mondo_term: 'MONDO Term',
      mondo_term_tooltip: '# of participants with the exact MONDO term',
      phenotype_code: 'Phenotype (HPO)',
      phenotype_source_text: 'Phenotype (Source Text)',
      interpretation: 'Interpretation',
      hpo_term: 'HPO Term',
      hpo_term_tooltip: '# of participants with the exact HPO term',
      observed: 'Observed',
      not_observed: 'Not Observed',
      participantsBySex: 'Participants by Sex',
      participantsByEthnicity: 'Participants by Ethnicity',
      participantsByStudy: 'Participants by Study',
      participantsByDataType: 'Participants by Data Type',
      participantsByStrategy: 'Participants by Strategy',
      participantsByPopulation: 'Participants by Population',
      participantsByDomain: 'Participants by Domain',
    },
    file: {
      file: 'File',
      files: 'Files',
      datafile: 'Data File',
      datafiles: 'Data Files',
      filesAuto: '{count, plural, =0 {File} =1 {File} other {Files}}',
      identifiant: 'ID',
      file_id: 'File ID',
      file_2_id: 'File ID',
      name: 'Name',
      file_name: 'File Name',
      file_format: 'Format',
      file_size: 'Size',
      ferload_url: 'URL',
      file_hash: 'Hash',
      analysis: 'Analysis',
      type: 'Type',
      data_type: 'Data Type',
      data_type_info: 'Understand CQDG data types and methodology.',
      file_authorization: 'File Authorization',
      fileReadMore: 'Requesting data access',
      locked: 'You are unauthorized to access this file. Read more on ',
      unlocked: 'You have access to this file.',
      controlled: 'Controlled',
      authorized: 'Authorized',
      unauthorized: 'Unauthorized',
      registered: 'Registered',
      category: 'Category',
      data_category: 'Data Category',
      data_access: 'Data Access',
      specialized_datasets: 'Specialized Datasets',
      datasetInfo:
        'The following specialized datasets have been made available by the study. A specialized dataset is a subset of the data which can be linked to a publication, the result of a particular analysis, a data collection event, or any other point of interest that the study wishes to highlight.',
      dataset: 'Dataset',
      experimentalProcedure: 'Experimental Procedure',
      analysisProperties: 'Analysis Properties',
      strategy: 'Strategy',
      sequencing_experiment: {
        experimental_strategy: 'Experimental Strategy',
        type_of_sequencing: 'Sequencing Type',
        read_length: 'Read Length',
        platform: 'Platform',
        capture_kit: 'Capture Kit',
        sequencer_id: 'Sequencer',
        run_date: 'Date (yyyy-mm-dd)',
        run_name: 'Run',
        labAliquotID: 'Aliquot',
        bio_informatic_analysis: 'Analysis Type',
        workflow_name: 'Pipeline',
        workflow_version: 'Version',
        genome_build: 'Genome Build',
        analysis_id: 'Analysis ID',
        identifiant: 'ID',
      },
      analysisFiles: 'Files Generated by the Analysis',
      numberByExperimentalStrategy: 'File counts by Strategy',
      numberByDataTypes: 'File counts by Data Type',
      'n=2': '(n={count})',
      nTooltipParticipant: 'Total number of files associated with the participant',
      nTooltipFile: 'Total number of files in the study',
    },
    variant: {
      title: 'Variants',
      variant: 'Variant',
      variants: 'Variants',
      variantAuto: '{count, plural, =0 {Variant} =1 {Variant} other {Variants}}',
      type: 'Type',
      variant_class: 'Variant Type',
      variant_external_reference: 'External Reference',
      variant_external_references: 'External References',
      variant_id: 'Variant ID',
      zygosity: 'Zygosity',
      transmission: 'Transmission',
      panels: 'Gene Panels',
      gnomAD: 'gnomAD',
      gnomADTooltip: 'gnomAD Genome 3.1.2 (allele frequency)',
      gnomADAlt: 'gnomAD ALT',
      gnomADAltTooltip: 'gnomAD Genome 3.1.2 (# of alternative alleles)',
      gnomadGenome311: 'gnomAD Genome (v3.1.1)',
      gnomadGenome3: 'gnomAD Genome (v3.1.2)',
      genome_build: 'Genome build',
      dbsnp: 'dbSNP',
      chromosome: 'Chromosome',
      position: 'Position',
      cytoband: 'Cytoband',
      alternativeAllele: 'Alternative allele',
      referenceAllele: 'Reference allele',
      referenceGenome: 'Reference Genome',
      sources: 'Sources',
      genes: 'Genes',
      gene: 'Gene',
      geneType: 'Gene Type',
      genePhenotype: 'Gene - Phenotype',
      mane: 'MANE',
      manePlus: 'MANE Plus',
      maneSelect: 'MANE Select',
      canonical: 'Ensembl Canonical',
      ensembl: 'Ensembl',
      noGene: 'No Gene',
      typeAbrv: {
        insertion: 'Ins',
        deletion: 'Del',
        snv: 'SNV',
        null: 'ND',
        indel: 'Ind',
        substitution: 'Sub',
        sequence_alteration: 'SeqAlt',
      },
      typeAbrvTooltip: {
        insertion: 'Insertion',
        deletion: 'Deletion',
        snv: 'SNV',
        null: 'No Data',
        indel: 'Indel',
        substitution: 'Substitution',
        sequence_alteration: 'Sequence Alteration',
      },
      studiesTooltip: '# of studies with affected participants',
      homozygotes: {
        title: 'Homo.',
        tooltip: '# of homozygotes for alternative alleles',
      },
      alt: {
        title: 'ALT',
        tooltip: '# of alternative alleles',
      },
      consequences: {
        consequence: 'Consequence',
        consequences: 'Consequences',
        geneConsequences: 'Gene Consequences',
        impactTag: {
          modifier: 'MODIFIER',
          low: 'LOW',
          moderate: 'MODERATE',
          high: 'HIGH',
        },
        impactTooltip: {
          HIGH: 'High',
          LOW: 'Low',
          MODERATE: 'Moderate',
          MODIFIER: 'Modifier',
        },
        predictions: {
          predictions: 'Predictions',
          prediction: 'Prediction',
          sift: 'Sift',
          polyphen2: 'Polyphen2',
          fathmm: 'Fathmm',
          cadd: 'CADD',
          caddPhred: 'CaddPhred',
          caddRaw: 'CaddRaw',
          caddTooltip: 'CADD (Phred score)',
          dann: 'Dann',
          lrt: 'Lrt',
          revel: 'REVEL',
        },
        aaColumn: 'AA',
        aaColumnTooltip: 'Amino acid substitution',
        cdnaChangeColumn: 'Coding DNA',
        conservation: 'Conservation',
        strand: 'Strand',
        vep: 'VEP',
        transcript: 'Transcript',
        transcripts: 'Transcripts',
        refSeq: 'RefSeq',
        seeMoreRefSeq: 'RefSeq - {ensemblTranscriptId}',
        omim: 'OMIM',
        omimTooltip: 'MIM inheritance modes',
        hideTranscript: 'Show less',
        showTranscript: '{count} other transcripts',
        canonical: 'Canonical transcript',
      },
      details: {
        functionalScores: 'Functional Scores',
        geneConstraints: 'Gene Constraints',
        spliceAltering: 'Splice Altering',
        associatedConditions: 'Associated Conditions (OMIM)',
        sift: 'SIFT',
        fathmm: 'FATHMM',
        caddPhred: 'CADD (Phred)',
        caddRaw: 'CADD (Raw)',
        dann: 'DANN',
        lrt: 'LRT',
        revel: 'REVEL',
        rEVEL: 'REVEL',
        polyphen2: 'PolyPhen-2 HVAR',
        polyphen2hvar: 'PolyPhen-2 HVAR',
        phyloP17Way: 'PhyloP17Way',
        pickedTooltip: 'Gene with most deleterious consequence',
        spliceAi: 'SpliceAI',
        pli: 'pLI',
        loeuf: 'LOEUF',
        spliceAiType: {
          AG: 'Acceptor gain',
          AL: 'Acceptor loss',
          DG: 'Donor gain',
          DL: 'Donor loss',
        },
      },
      frequencies: {
        frequency: 'Frequency',
        frequencyOnlyWGS: 'Frequency (only WGS)',
        frequencies: 'Frequencies',
        frequencyTooltip: 'Frequency of the variant across CQDG studies',
        publicCohorts: 'Public Cohorts',
        studies: 'Studies',
        domain: 'Domain',
        part: 'Part.',
        freq: 'Freq.',
        freqTooltip: 'Allelic frequency of the variant across CQDG cohorts (whole genomes only)',
        participants: 'Participants',
        participantsTooltip:
          'Number and frequency of participant carriers in the CQDG cohorts (whole genomes only)',
        participantsInfoIconTooltip:
          'Due to participant confidentiality, links may return a smaller number than displayed',
        altAlleles: '# ALT Alleles',
        altAllelesTooltip: 'Number of alternative alleles',
        altRef: '# Alleles (ALT + REF)',
        altRefTooltip: 'Alternative alleles + Reference alleles',
        homozygotes: '# Homozygotes',
        homozygotesTooltip: 'Number of homozygote variants',
        total: 'Total',
        cohort: 'Cohort',
        internal_frequencies_wgs_af: 'CQDG Allele Frequency (WGS)',
        internal_frequencies_wgs_pf: 'CQDG Participant Frequency (WGS)',
      },
      pathogenicity: {
        pathogenicity: 'Pathogenicity',
        pathogenicities: 'Pathogenicities',
        clinVar: 'ClinVar',
        clinvarAbrv: {
          conflicting_interpretations_of_pathogenicity: 'CI',
          benign: 'B',
          likely_benign: 'LB',
          uncertain_significance: 'VUS',
          pathogenic: 'P',
          not_provided: 'NP',
          drug_response: 'DR',
          risk_factor: 'RF',
          likely_pathogenic: 'LP',
          association: 'AS',
          other: 'O',
          affects: 'AF',
          protective: 'PV',
          confers_sensitivity: 'CS',
          uncertain_risk_allele: 'URA',
          association_not_found: 'ANF',
          likely_risk_allele: 'LRA',
          low_penetrance: 'LPN',
        },
        clinVarLabel: {
          affects: 'Affects',
          association: 'Association',
          association_not_found: 'Association Not Found',
          benign: 'Benign',
          confers_sensitivity: 'Confers Sensitivity',
          conflicting_interpretations_of_pathogenicity:
            'Conflicting Interpretations Of Pathogenicity',
          drug_response: 'Drug Response',
          likely_benign: 'Likely Benign',
          likely_pathogenic: 'Likely Pathogenic',
          likely_risk_allele: 'Likely Risk Allele',
          low_penetrance: 'Low Penetrance',
          not_provided: 'Not Provided',
          null: 'No Data',
          other: 'Other',
          pathogenic: 'Pathogenic',
          protective: 'Protective',
          risk_factor: 'Risk Factor',
          uncertain_risk_allele: 'Uncertain Risk Allele',
          uncertain_significance: 'Uncertain Significance',
        },
        pathoClinvar: 'Pathogenicity (ClinVar)',
        condition: 'Condition',
        inheritance: 'Inheritance',
        source: 'Source',
        interpretation: 'Interpretation',
        germline: 'Germline',
        germlineAbvr: 'GER',
        somaticAbvr: 'SOM',
        somatic: 'Somatic',
      },
      conditions: {
        title: 'Condition',
        tableTitle: 'Gene - Phenotype Association',
      },
      mostDeleteriousConsequence: {
        title: 'Most Deleterious Consequence',
        tooltip: 'Functional consequences of genetic variations annotated using VEP',
      },
      inheritant: {
        code: {
          AD: 'Autosomal Dominant',
          AR: 'Autosomal Recessive',
          DD: 'Digenic Dominant',
          DR: 'Digenic Recessive',
          IC: 'Isolated Cases',
          Mi: 'Mitochondrial',
          MI: 'Mitochondrial Inheritance',
          Mu: 'Multifactorial',
          NRT: 'No Reported Transmission',
          SMo: 'Somatic Mosaicism',
          Smu: 'Somatic Mutation',
          XL: 'X-Linked',
          XLD: 'X-Linked Dominant',
          XLR: 'X-Linked Recessive',
          YL: 'Y-Linked',
        },
      },
    },
  },
  global: {
    yes: 'Yes',
    no: 'No',
    other: 'Other',
    delete: 'Delete',
    unknown: 'Unknown',
    summary: 'Summary',
    copyToClipboard: 'Copy to clipboard',
    viewInDataExploration: 'View in Data Explorer',
    search: {
      genes: {
        emptyText: 'No gene found',
        placeholder: 'e.g. BRAF, ENSG00000157764',
        title: 'Search by gene',
        tooltip: 'Enter Gene Symbol, Gene Alias or Ensembl ID',
      },
      variants: {
        emptyText: 'No variant found',
        placeholder: 'e.g. 10-100063679-T-C, rs341',
        title: 'Search by variant',
        tooltip:
          'Enter Variant Locus, Gene Symbol, Gene Alias, Gene AA Change, dbSNP ID, ClinVar ID, Ensembl ID, refseq ID',
      },
      study: {
        emptyText: 'No study found',
        placeholder: 'e.g. DEE, Developmental and Epileptic Encephalopathies',
        title: 'Search study',
        tooltip: 'Search by study code, name, domain or keyword',
      },
    },
    filters: {
      actions: {
        all: 'All',
        none: 'None',
        clear: 'Clear',
        clearFilters: 'Clear filters',
        less: 'Less',
        more: 'More',
        apply: 'Apply',
        dictionnary: 'Dictionnary',
        cancel: 'Cancel',
      },
      operators: {
        allOf: 'All of',
        anyOf: 'Any of',
        noneOf: 'None of',
        between: 'Between',
        lessthan: 'Less than',
        lessthanorequal: 'Less than or equal',
        greaterthan: 'Greater than',
        greaterthanorequal: 'Greater than or equal',
      },
      range: {
        is: 'Is',
        from: 'From',
        to: 'To',
        actualInterval: 'Actual interval',
      },
      messages: {
        empty: 'No values found',
      },
      checkbox: {
        placeholder: 'Search...',
      },
    },
    forms: {
      errors: {
        minCharacters: 'characters minimum',
        requiredField: 'This field is required',
        enterValidEmail: 'Enter a valid email',
      },
    },
    errors: {
      403: 'Sorry, you are not authorized to access this page.',
      404: 'Sorry, the page you visited does not exist.',
      500: 'Sorry, something went wrong.',
      backHome: 'Back home',
      query: {
        notFound: {
          title: 'Query not found',
          content:
            'We were unable to load your query. Please try again or <a href="{href}" style="text-decoration: underline;" target="_blank">contact support</a>.',
          okText: 'Close',
        },
      },
    },
    notification: {
      genericError: 'An error occured',
    },
    proTable: {
      results: 'Results',
      result: 'Result',
      noResults: 'No Result',
      of: 'of',
      selected: 'item selected',
      selectedPlural: 'items selected',
      selectAllResults: 'Select all results',
      clear: 'Clear',
      clearFilters: 'Clear filters',
      tableExport: 'Export as TSV',
      reset: 'Reset',
      columns: 'Columns',
      first: 'First',
      previous: 'Previous',
      next: 'Next',
      view: '{value} / view',
    },
    quickFilter: {
      emptyMessage: 'Min. 3 characters',
      menuTitle: 'Quick Filter',
      placeholder: 'Search...',
      results: 'Results',
    },
    seeLess: 'See less',
    seeMore: 'See more',
    ferload: 'Ferload',
  },
  api: {
    savedFilter: {
      error: {
        title: 'Error',
        messageUpdate: 'Unable to update filter',
        messageDelete: 'Unable to delete filter',
      },
    },
    savedSet: {
      error: {
        title: 'Error',
        messageUpdate: 'Unable to update set',
        messageDelete: 'Unable to delete set',
        messageCreate: 'Unable to create set',
      },
      success: {
        titleCreate: 'Your set has been saved.',
        messageCreate: 'You can add your sets to a query from the sidebar or the dashboard.',
        titleUpdate: 'Success',
        messageUpdate: 'Your set has been updated.',
        titleDelete: 'Success',
        messageDelete: 'Your set has been deleted.',
      },
    },
    report: {
      error: {
        title: 'Error',
        message: 'We were unable to generate the report at this time. Please try again later or ',
        support: 'contact support',
        tooMuchFilesTitle: 'Maximum number exceeded',
        tooMuchFiles:
          'A maximum of {limit} files can be included at a time. Please narrow your selection and try again.',
      },
      inProgress: {
        title: 'Processing',
        fetchReport: 'Fetching Report, please wait',
      },
      onSuccess: {
        title: 'Success',
        fetchReport: 'Report downloaded successfully',
      },
      clinicalData: {
        download: 'Download clinical data',
        family:
          '{count, plural, =0 {Selected participant & family} =1 {Selected participant & family} other {Selected participants & families}}',
        participant:
          '{count, plural, =0 {Selected participant} =1 {Selected participant} other {Selected participants}}',
      },
      sampleData: {
        download: 'Download sample data',
      },
      fileManifest: {
        button: 'Manifest',
        title: 'File manifest',
        okText: 'Download',
        cancel: 'Cancel',
        text: `Download a manifest of the selected files which can be used with CQDG's bulk download tool. This manifest also includes additional information, including the participants and samples associated with these files.`,
        textStudy: `Download a manifest of this study’s files which can be used with CQDG's bulk download tool. This manifest also includes additional information, including the participants and samples associated with these files.`,
        textDataset: `Download a manifest of this dataset’s files which can be used with CQDG's bulk download tool. This manifest also includes additional information, including the participants and samples associated with these files.`,
        textCheckbox: `Include data files of the same type for the participants' related family members for this selection.`,
        textCheckboxSet:
          'Include the files of the same type for related family members in this set.',
        tooltip: 'File manifest for the tool ',
        manifestIdButton: 'Copy manifest ID',
        manifestIdButtonTooltip: 'Copy the manifest ID for use in ',
        manifestIdCopySuccess: 'ID copied to clipboard',
        manifestIdCopyError: 'ID copy to clipboard error',
      },
      requestAccess: {
        button: 'Request access',
        title: 'Request access',
        okText: 'Download',
        cancel: 'Cancel',
        text: 'To obtain access to the data from this study, please submit your request to the study’s Access Authority:',
        text2:
          'Before submitting your request, please ensure you are able to comply with the study’s Access Limitations and Access Requirements listed in the “Data Access” section.',
        text3: 'To find out more, please read the page ',
        content:
          "Download an archive containing documents to guide you through your access requests. You will find terms of use and the Access Authority contact information for each study, as well as a list of files included in each study's access request.",
        content2:
          'For more information, consult the README_EN file in the archive or this documentation page: ',
        textLink: 'Data Access Request',
        textCheckbox: `Include the files of the same type for related family members in this selection.`,
      },
    },
    noData: 'No data',
    noDataAvailable: 'No data available',
  },
  components: {
    search: {
      searchByIds: 'Search by IDs',
      selectSavedSet: 'Select a saved set',
      searchByParticipantIdTooltip: 'Search by participant ID or external participant ID',
      savedParticipantSets: 'Saved participant sets',
      noParticipantFound: 'No participant found',
      noParticipantSetFound: 'No participant sets found',
      participantPlaceholder: 'e.g. PT0483333',
      searchBySampleIdTooltip: 'Search by sample ID or external sample ID',
      savedSampleSets: 'Saved sample sets',
      noSampleFound: 'No sample found',
      noSampleSetFound: 'No sample sets found',
      samplePlaceholder: 'e.g. SR0230956',
      noFileFound: 'No file found',
      filePlaceholder: 'e.g. FI0080837',
      noFileSetFound: 'No file set found',
      savedFileSets: 'Saved file sets',
      searchByFileIdTooltip: 'Search by file ID',
    },
    uploadIds: {
      participantPlaceholder: 'e.g. PT0483333',
      samplePlaceholder: 'e.g. SR0230956',
      filePlaceholder: 'e.g. FI0080837',
      sampleTitle: 'Upload a sample list',
      gene: 'gene',
      geneID: 'Gene Symbol, Gene Alias, Ensembl ID',
      genePlaceholder: 'e.g. ENSG00000157764, TP53',
      title: 'Upload a {entity} list',
      submittedColTitle: 'Submitted {entity} identifiers',
      uploadBtnText: 'Upload a {entity} list',
      mappedTo: 'Mapped To',
      collapseTitle: 'Summary Table  ({matchCount} matched, {unMatchCount} unmatched)',
      inputLabel: 'Copy-paste a list of identifiers or upload a file',
      match: 'Matched ({count})',
      unmatch: 'Unmatched ({count})',
      tableMessage:
        '{submittedCount} submitted identifiers mapped to {mappedCount} unique system identifiers',
      matchTable: {
        idcol: '{entity} ID',
        participant: {
          matchcol: 'Participant ID',
          mappedcol: 'Study Code',
        },
        file: {
          matchcol: 'File ID',
          mappedcol: 'Study Code',
        },
        biospecimen: {
          matchcol: 'Sample ID',
          mappedcol: 'Study Code',
        },
        gene: {
          matchcol: 'Ensembl ID',
          mappedcol: 'Symbol',
        },
      },
      pillTitle: 'Uploaded List',
      upload: {
        fileBtn: 'Upload a file',
        btn: 'Upload',
      },
      clearBtn: 'Clear',
      cancelBtn: 'Cancel',
      emptyTable: 'No data',
      popover: {
        title: 'Identifiers and File Formats',
        identifiers: 'Identifiers',
        separatedBy: {
          title: 'Separated by',
          values: 'comma, space, new line',
        },
        uploadFileFormats: 'Upload file formats',
      },
    },
    filterList: {
      collapseAll: 'Collapse all',
      expandAll: 'Expand all',
    },
    querybuilder: {
      defaultTitle: 'Untitled Filter',
      header: {
        modal: {
          edit: {
            title: 'Save this filter',
            okText: 'Save',
            cancelText: 'Cancel',
            input: {
              label: 'Filter name',
              placeholder: 'Untitled filter',
              maximumLength: 'characters maximum',
            },
          },
          saveThisFilter: 'Save this filter',
          confirmUnsaved: {
            title: 'Unsaved changes',
            openSavedFilter: {
              okText: 'Continue',
              cancelText: 'Cancel',
              content: 'You are about to open a saved filter; all modifications will be lost.',
            },
            createNewFilter: {
              okText: 'Create',
              cancelText: 'Cancel',
              content: 'You are about to create a new filter; all modifications will be lost.',
            },
          },
        },
        popupConfirm: {
          delete: {
            title: 'Permanently delete this filter?',
            okText: 'Delete filter',
            cancelText: 'Cancel',
            content: 'You are about to permanently delete this filter and all of its queries.',
          },
        },
        tooltips: {
          newQueryBuilder: 'New filter',
          save: 'Save filter',
          saveChanges: 'Save changes',
          delete: 'Delete',
          duplicateQueryBuilder: 'Duplicate filter',
          saveDisabled: 'Add a query to save',
          share: 'Share (Copy url)',
          shareDisabled: 'Save filter to share',
          setAsDefaultFilter: 'Set as default filter',
          unsetDefaultFilter: 'Unset default filter',
          undoChanges: 'Discard unsaved changes',
          noSavedFilters: 'You have no saved filters',
        },
        myFiltersDropdown: {
          title: 'My filters',
          manageMyFilter: 'Manage my filters',
          okText: 'Close',
        },
        duplicateFilterTitleSuffix: 'COPY',
      },
      query: {
        combine: {
          and: 'and',
          or: 'or',
        },
        noQuery: 'Use the search tools & facets on the left to build a query',
      },
      actions: {
        new: 'New',
        changeOperatorTo: 'Change operator to',
        addQuery: 'New query',
        combine: 'Combine',
        labels: 'Labels',
        delete: {
          title: 'Delete this query?',
          titleSelected: 'Delete this query?',
          cancel: 'Cancel',
          confirm: 'Delete',
        },
        clear: {
          title: 'Delete all queries?',
          cancel: 'Cancel',
          confirm: 'Delete',
          buttonTitle: 'Clear all',
          description: 'You are about to delete all your queries. They will be lost forever.',
        },
      },
      pattern: 'Permitted characters: A-Z a-z 0-9 ()[]-_:|.,',
    },
    savedSets: {
      modal: {
        edit: {
          title: 'Edit set',
          okText: 'Save',
          cancelText: 'Cancel',
          input: {
            label: 'Set name',
            placeholder: 'Enter the name of your new set',
            maximumLength: 'characters maximum',
          },
          setAlreadyExists: 'A set with this name already exists',
        },
        add: {
          title: 'Add to a {type} set',
          okText: 'Add to set',
          cancelText: 'Cancel',
          enterName: 'Enter the name of your new set',
          name: 'Set name',
        },
        remove: {
          title: 'Remove from a {type} set',
          okText: 'Remove from set',
          cancelText: 'Cancel',
        },
      },
      popupConfirm: {
        delete: {
          title: 'Permanently delete this set?',
          okText: 'Delete set',
          cancelText: 'Cancel',
          content: 'You are about to permanently delete this set.',
        },
      },
    },
    dataRelease: {
      title: 'Available Data',
      dataReleaseLink: 'Version 1.0',
      dataExploration: 'Data Exploration',
    },
  },
  layout: {
    main: {
      menu: {
        dashboard: 'Dashboard',
        studies: 'Studies',
        explore: 'Data Explorer',
        variants: 'Variants',
        website: 'Website',
        documentation: 'Documentation',
        dictionary: 'Dictionary',
        community: 'Community',
        resources: 'Resources',
        contact: 'Contact',
        downloadTool: 'Download Tool',
      },
    },
    user: {
      menu: {
        settings: 'Profile settings',
        logout: 'Logout',
        signedWith: 'Signed in with',
      },
    },
  },
  screen: {
    loginPage: {
      title: 'Data Portal',
      resume:
        "The Quebec Genomic Data Center is a data harmonization and sharing platform for genomic datasets produced by Quebec's clinical and research studies.",
      login: 'Login',
      signup: 'Sign up',
      studies: {
        title: 'Studies',
        summary: 'Explore harmonized data from studies in both pediatric and adult populations.',
        cartagene: {
          subtitle: 'CARTaGENE',
          description:
            '<p>CARTaGENE is a public research platform of the CHU Sainte-Justine created to accelerate health research. CARTaGENE consists of both biological samples and health data from 43,000 Quebec residents aged between 40 to 69 years.</p><p>The dataset currently available in the CQDG contains whole genome sequencing (WGS) data for 2,179 individuals.</p>',
        },
        dee: {
          title: 'DEE',
          subtitle: 'Investigating the genetic causes of epileptogenic encephalopathies',
          description:
            '<p>Whole Genome Sequencing (WGS) study in children with epileptogenic encephalopathies.</p><p>The dataset currently available in the CQDG contains whole genome sequencing data for 588 individuals belonging to 196 families.</p>',
        },
        bacq: {
          title: 'BACQ',
          subtitle: 'Quebec congenital malformation biobank',
          description:
            '<p>Biobank of well-characterized affected cases as well as their parents allowing molecular genetic studies to be carried out to identify the genes associated with these malformations.</p><p>The dataset currently available in the CQDG contains whole exome sequencing (WXS) data for 386 individuals belonging to 124 families.</p>',
        },
        pragmatiq: {
          title: 'PRAGMatIQ',
          subtitle: 'Applying rapid pediatric genomics in Quebec',
          description:
            '<p>The objective of the project is to offer rapid clinical Whole Genome Sequencing (WGS) to all critically ill newborns and infants who could benefit from this test in Quebec.</p><p>Data for 257 individuals has been submitted to the CQDG. The dataset currently available in the CQDG contains whole genome sequencing data for 110 individuals belonging to 38 families.</p>',
        },
        neurodev: {
          title: 'NeuroDev',
          subtitle: 'Neurodevelopmental disorders',
          description:
            '<p>The main objective of this project is to constitute a bank of samples and data from children and adults with global developmental delay, epilepsy and/or malformations and members of their family to supply various research projects which aim to identify the genes involved in these conditions.</p><p>Data for 382 individuals has been submitted to the CQDG. The dataset currently available in the CQDG contains whole genome sequencing data for 67 individuals belonging to 22 families.</p>',
        },
      },
      cards: {
        stats: {
          release: 'Release v1.0',
          genomes: 'Genomes',
          exomes: 'Exomes',
        },
        rare: {
          title: 'Network to advance research on rare diseases in Quebec',
          description:
            'The CQDG is a proud partner of the new Réseau pour Avancer la REcherche sur les maladies rares au Québec (Network to advance research on rare diseases in Quebec or RARE.Qc) financed by the FRQS. RARE.Qc is a coalition of over 100 researchers and patient partners across Quebec, aiming to transform research on rare diseases, particularly by promoting collaboration and data sharing using the CQDG infrastructure.',
        },
        secureData: {
          title: 'CQDG in the Cloud',
          description:
            'Analyse CQDG datasets with your own software using the Secure Data for Health (SD4H) cloud-based infrastructure. Other cloud providers will be integrated with CQDG in a near future. Contact us to learn more.',
          contact: 'Contact us',
        },
        variants: {
          title: 'Germline Variants',
          description:
            'Our variant explorer offers advanced searching capabilities. With just a few clicks, you can explore millions of annotated germline variants from genomes and exomes of the CQDG participants.',
          explore: 'Explore variant data',
        },
      },
      documentation: {
        title: 'Documentation Center',
        description:
          'To learn how to submit your data, request access to data, or to read user guides for the portal, visit the Documentation Center.',
        button: 'Documentation Center',
      },
      hosting: {
        title: 'Need to Host Your Data?',
        description:
          'The CQDG offers an ideal platform for researchers to store and share genomic data produced by clinical and research studies.',
        button: 'Submit your data',
      },
    },
    memberProfile: {
      notFound: 'User not found',
      rolesTitle: 'Roles',
      noRoles: 'No roles',
      usageTitle: 'Intended Use of the CQDG Portal Data',
      researchDomainsTitle: 'Research domains or domains of interest',
      noUsage: 'No intended usages',
      noResearchDomain: 'No research domain',
      editProfileBtn: 'Edit Profile',
      communityBtn: 'Community',
    },
    community: {
      title: 'CQDG Community',
      resultsMember: 'Members',
      noMember: 'No member',
      search: {
        filters: 'Filters',
        inputPlaceholder: 'e.g. Bergeron, Sainte-Justine',
        selectPlaceholder: 'Select',
        role: 'Role',
        dataUse: 'Data use',
        researchDomain: 'Research Domain',
        clearFilters: 'Clear filters',
        barPlaceholder: 'Search by name or affiliation',
        noResult: 'No result',
        sorter: {
          newest: 'Newest first',
          oldest: 'Oldest first',
          lastnameAlpha: 'Alphabetical (last name)',
        },
      },
    },
    profileSettings: {
      title: 'Profile settings',
      viewProfile: 'View profile',
      cards: {
        deleteAccount: {
          title: 'Delete Account',
          button: 'Delete my account',
          notice:
            'You will no longer be able to sign into the CQDG data portal. All of your saved sets and queries will be lost. You can create a new account at any time.',
          confirm: {
            content: 'Are you sure you want to permanently delete this account?',
          },
        },
        identification: {
          title: 'Identification',
          alert:
            'You are authenticated with <strong>{provider}</strong> using <strong>{email}</strong>. This email is never shown to the public and cannot be changed.',
          firstName: 'First Name',
          yourFirstName: 'Your First Name',
          lastName: 'Last Name',
          yourLastName: 'Your Last Name',
          publicEmail: 'Public Email',
          publicEmailNotice:
            'This email will be displayed on your profile page and accessible to all logged-in users of the portal.',
          editPhotoModalTitle: 'Edit photo',
          uploadImageError: 'Unable to upload your image at the moment',
          removePhotoModalTitle: 'Remove profile photo?',
          removePhotoModalButton: 'Yes remove photo',
          removePhotoModalMessage:
            'Are you sure you want to remove your photo? We will replace it with a default avatar.',
          uploadPhotoButton: 'Upload photo',
          removePhotoButton: 'Remove photo',
          linkedinUrl: 'Please enter a valid LinkedIn URL',
        },
        roleAffiliation: {
          title: 'Role & Affiliation',
          iama: 'I am a',
          checkAllThatApply: 'Check all that apply',
          provideAffiliation: 'Provide institutional or organizational affiliation',
          affiliatedWith: 'I am affiliated with',
          dontHaveAffiliation: 'I do not have an institutional affiliation',
          describeResearchArea: 'My research area or area of interest may best be described as',
          provideABriefLink:
            'Provide a brief description and a link to your professional biography or organization website, if available',
        },
        researchDomain: {
          title: 'Research Domain',
          label: 'Research domains or domains of interest',
          checkAll: 'Check all that apply',
        },
        saveChanges: 'Save changes',
        discardChanges: 'Discard changes',
      },
      roleOptions: {
        researcher_in_academic_or_non_profit_institution:
          'Researcher in an academic or non-profit institution',
        representative_of_commercial_or_for_profit_company:
          'Representative of a commercial or for-profit company',
        bioinformatician_software_developer: 'Bioinformatician, software developer',
        clinician: 'Clinician',
        employee_in_governmental_agency: 'Employee in a governmental agency',
        other: 'Other',
      },
      researchDomainOptions: {
        aging: 'Aging',
        bioinformatics: 'Bioinformatics',
        birth_defects: 'Birth Defects',
        cancer: 'Cancer',
        circulatory_respiratory_health: 'Circulatory and Respiratory Health',
        general_health: 'General Health',
        infection_immunity: 'Infection and Immunity',
        musculoskeletal_health_arthritis: 'Musculoskeletal Health and Arthritis',
        neurodevelopmental_conditions: 'Neurodevelopmental Conditions',
        neurosciences_mental_health_addiction: 'Neurosciences, Mental Health and Addiction',
        nutrition_metabolism_diabetes: 'Nutrition, Metabolism and Diabetes',
        population_genomics: 'Population Genomics',
        rare_diseases: 'Rare Diseases',
        not_applicable: 'Not Applicable',
        other: 'Other',
      },
    },
    dashboard: {
      hello: 'Hello',
      cards: {
        error: {
          title: 'Connection error',
          subtitle:
            'We are currently unable to connect to this service. Please refresh the page and try again. If the problem persists, please',
          pleaseRefresh: 'Please refresh and try again or ',
          failedFetchFilters: 'Failed to fetch Saved Filters',
          failedFetchSets: 'Failed to fetch Saved Sets',
          contactSupport: 'contact our support',
        },
        savedFilters: {
          title: 'My Filters',
          popoverTitle: 'Managing Saved Filters',
          noSaved:
            'A filter is a collection of queries applied to all harmonized CQDG data. The results of a filter may change over time as CQDG data is updated.',
          howToCreate: 'How to create a filter',
        },
        savedSets: {
          title: 'My Sets',
          popoverTitle: 'Managing Saved Sets',
          noSaved:
            'A set is a fixed collection of entities (participants, biospecimens, files, or variants) which does not change even if CQDG data is updated.',
          howToCreate: 'How to create a set',
        },
        lastSaved: 'Last saved: {date} ago',
        learnMore: 'Learn more',
      },
    },
    variants: {
      title: 'Germline Variant Database',
      subTitle: 'Explore all the germline variants submitted by the CQDG studies.',
      variantsExploration: 'Variants Exploration',
      sidemenu: {
        participant: 'Participant',
        variant: 'Variant',
        gene: 'Gene',
        frequency: 'Frequency',
        pathogenicity: 'Pathogenicity',
      },
    },
    studies: {
      title: 'Study Directory',
      code: 'Code',
      name: 'Name',
      domain: 'Domain',
      population: 'Population',
      participants: 'Participants',
      families: 'Families',
      genomics: 'Genomics',
      transcriptomics: 'Transcriptomics',
      imaging: 'Imaging',
      files: 'Files',
      accessLimitation: 'Access Limitation',
      accessRequirement: 'Access Requirement',
      sampleAvailability: 'Sample Availability',
      description: 'Description',
      searchLabel: {
        title: 'Search by study code, name, domain, or keyword',
        placeholder: 'e.g. CAG, CARTaGENE, Rare Diseases, Epilepsy',
      },
    },
    dataExploration: {
      title: 'Data Explorer',
      subTitle: 'Explore and build virtual cohorts with participants from multiple CQDG studies.',
      sidemenu: {
        participant: 'Participant',
        biospecimen: 'Biospecimen',
        datafiles: 'Data File',
      },
      hpoTree: {
        modal: {
          title: 'Observed Phenotype (HPO) Browser',
          okText: 'Apply',
          cancelText: 'Cancel',
        },
        searchPlaceholder: 'Search for ontology term - min 3 characters',
        emptySelection: 'Select items from the left-hand pane in order to add to your query.',
        tags: {
          exact: 'Participants with this exact term',
          all: 'Participants including descendant terms',
        },
      },
      mondoTree: {
        modal: {
          title: 'Diagnosis (MONDO) Browser',
          okText: 'Apply',
          cancelText: 'Cancel',
        },
        searchPlaceholder: 'Search for ontology term - min 3 characters',
        emptySelection: 'Select items from the left-hand pane in order to add to your query.',
        tags: {
          exact: 'Participants with this exact term',
          all: 'Participants including descendant terms',
        },
      },
      icdTree: {
        modal: {
          title: 'Diagnosis (ICD-10) Browser',
          okText: 'Apply',
          cancelText: 'Cancel',
        },
        searchPlaceholder: 'Search for ontology term - min 3 characters',
        emptySelection: 'Select items from the left-hand pane in order to add to your query.',
        tags: {
          exact: 'Participants with this exact term',
          all: 'Participants including descendant terms',
        },
      },
      tabs: {
        summary: {
          title: 'Summary',
          global: {
            nbParticipant: '{count} participants (including descendant terms on this path)',
            addTermToQuery: 'Add term to active query',
            currentPath: 'Current Path',
            centerSubtitleFormatter: 'Participants with',
          },
          download: {
            fileNameTemplate: 'cqdg-%name-%type-%date%extension',
            fileNameDateFormat: 'yyyy-MM-dd',
            download: 'Download',
            preview: 'Download preview - ',
            data: 'Download data',
            svg: 'Download SVG',
            png: 'Download PNG',
            removeChart: 'Remove chart',
          },
          columnSelector: {
            reset: 'Reset',
            tooltip: 'Charts',
          },
          observed_phenotypes: {
            cardTitle: 'Observed Phenotypes (HPO)',
            phenotypeTree: {
              nbParticipant: '{count} participants (including descendant terms on this path)',
              addTermToQuery: 'Add term to active query',
              currentPath: 'Current Path',
            },
            empty: 'No observed phenotypes reported for these participants',
          },
          mondo: {
            cardTitle: 'Diagnosis (MONDO)',
            phenotypeTree: {
              nbParticipant: '{count} participants (including descendant terms on this path)',
              addTermToQuery: 'Add term to active query',
              currentPath: 'Current Path',
            },
            empty: 'No diagnoses reported for these participants',
          },
          demographic: {
            cardTitle: 'Demographics',
            familyComposition: 'Family Composition',
            ethnicityTitle: 'Ethnicity',
          },
          ageAtDiagnosis: {
            cardTitle: 'Age at Diagnosis',
            _0to1: 'Newborn',
            _1to5: '[1, 5]',
            _5to10: '[5, 10]',
            _10to15: '[10, 15]',
            _15to18: '[15, 18]',
            _18plus: 'Adult',
          },
          studies: {
            cardTitle: 'Studies',
          },
          availableData: {
            dataCategoryTitle: 'Participants by Data Category',
            dataTypeTitle: 'Participants by Data Type',
            studiesTitle: 'Participants by Study',
            axis: '# of participants',
            dataCategory: 'Data Category',
            dataType: 'Data Type',
          },
          studiespie: {
            cardTitle: 'Studies',
            domainTitle: 'Domain',
            popTitle: 'Population',
            partTitle: 'Participants',
          },
        },
        participants: {
          title: 'Participants ({count})',
        },
        biospecimens: {
          title: 'Biospecimens ({count})',
        },
        datafiles: {
          title: 'Data Files ({count})',
          facet: 'Data File',
        },
      },
      saveSelection: 'Save current selection',
      participantsSelected: 'participants selected',
      participantSelected: 'participant selected',
      saveParticipantsSet: 'Save participant set',
      maxLimit: 'Max. {limit} items at a time. The first {limit} will be processed.',
      filesSelected: 'files selected',
      fileSelected: 'file selected',
      saveFilesSet: 'Save file set',
      biospecimensSelected: 'biospecimens selected',
      biospecimenSelected: 'biospecimen selected',
      saveBiospecimensSet: 'Save biospecimen set',
      variantsSelected: 'variants selected',
      variantSelected: 'variant selected',
      saveVariantsSet: 'Save variant set',
      searchByBiospecimenId: 'Search by biospecimen ID',
      saveAsNewSet: 'Create new set',
      addToExistingSet: 'Add to existing set',
      removeFromExistingSet: 'Remove from existing set',
      addToSet: 'Add to set',
      addTypeSet: 'Add to a {type} set',
      removeFromSet: 'Remove from set',
      removeTypeSet: 'Remove from a {type} set',
      set: 'Set',
      chooseSet: 'Choose a set',
      youMustSelect: 'You must select at least 1 item',
      comingSoon: 'Coming soon!',
    },
    cavatica: {
      title: 'Connect to Cavatica',
      analyseModal: {
        title: 'Analyze in Cavatica',
        copyFiles: 'Copy files',
        youAreAuthorizedToCopy: 'You are authorized to copy',
        countFiles: '{filesAuthorizedCount} files',
        ofFiles: ' (out of {filesTotalCount} selected) to your Cavatica workspace.',
        unauthorizedFiles: 'Unauthorized files',
        unauthorizedFilesDescription:
          'You are not authorized to analyze the files you have selected. Learn more about ',
        dataAccess: 'data access',
      },
    },
  },
  // this facets object is also used by Ferlab-ui to override the values in the facets
  // ex: variant genes sift_pred: T -> Tolerated
  facets: {
    options: {
      genes__consequences__predictions__sift_pred: {
        T: 'Tolerated',
        D: 'Damaging',
      },
      genes__consequences__predictions__polyphen2_hvar_pred: {
        B: 'Benign',
        D: 'Damaging',
        P: 'Possibly Damaging',
      },
      genes__consequences__predictions__fathmm_pred: {
        T: 'Tolerated',
        D: 'Damaging',
      },
      genes__consequences__predictions__lrt_pred: {
        N: 'Neutral',
        D: 'Deleterious',
        U: 'Unknown',
      },
      studies__zygosity: {
        HET: 'Heterozygote',
        WT: 'Wild Type',
        HOM: 'Homozygote',
        UNK: 'Unknown',
      },
      variant_external_reference: {
        DBSNP: 'dbSNP',
        Clinvar: 'ClinVar',
        Cosmic: 'COSMIC',
      },
      age_at_recruitment: ageCategoriesKeyLabelTooltip,
      mondo_tagged__age_at_event: ageCategoriesKeyLabelTooltip,
      age_biospecimen_collection: ageCategoriesKeyLabelTooltip,
      biospecimens__age_biospecimen_collection: ageCategoriesKeyLabelTooltip,
    },
  },
};

export default en;
